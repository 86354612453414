import React from "react";
import PageTemplate from "./base";
import imgHero from "../../assets/image/industries/supermarket.jpeg";

const Page = () => {
  return (
    <>
      <PageTemplate
        seoTitle="Free Scheduling Software for Supermarkets, Groceries & Convenience Stores | Camelo"
        metaDescription="Create staff schedules for supermarkets, groceries, and convenience stores in minutes, for free. Try the Camelo scheduling app today!"
        header="Money-saving and time-saving scheduling solution for supermarkets."
        subHeader="Camelo is a better way to schedule, connect, and manage your team."
        imgUrl={imgHero}
      />
    </>
  );
};

export default Page;
